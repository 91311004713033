<!--/**-->
<!--* 自动生成 vue列表代码【模块名：库存】-->
<!--* 生成日期：2024/04/25 15:10:40-->
<!--* 生成路径: src/pages/product_qty/ProductQtyList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='产品名称'>
              <el-input v-model='searchCondition.title' placeholder='产品名称' clearable></el-input>
            </el-form-item>
            <el-form-item label='条码'>
              <el-input v-model='searchCondition.barcode' placeholder='条码' clearable></el-input>
            </el-form-item>
            <el-form-item label='品牌'>
              <el-input v-model='searchCondition.brand' placeholder='品牌' clearable></el-input>
            </el-form-item>
            <el-form-item label='负责人'>
              <el-input v-model='searchCondition.leader_name' placeholder='负责人' clearable></el-input>
            </el-form-item>
            <el-form-item label='库存'>
              <el-input v-model="searchCondition.qty" class="input-with-select" :min="0" placeholder="库存数" clearable>
                <el-select v-model='searchCondition.qty_op' slot="prepend" placeholder='条件' style="width: 60px;">
                  <el-option :label='option.label' :value='option.value' :key='key'
                             v-for='(option, key) in qtyOpOptions'></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :maxHeight='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       :cell-class-name='addCellClass'
                       @row-click='rowClickEvent'
                       @cell-click='showInput'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='库存变更记录'>
              <template slot-scope='{row}'>
                <el-button type='text' icon='el-icon-view' @click='viewChangeList(row)'>查看</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>
      <el-dialog :title='distribFrom.info.title' :visible.sync='distribFrom.open' center width='50%' append-to-body
                 :close-on-click-modal='false'>
        <div style="width: 95%;margin: 20px auto">
          <el-table :data="distribFrom.list" border style="width: 100%">
            <el-table-column
              type="index"
              width="50"
              label='序号'
              header-align="center"
              align="center" />
            <el-table-column
              prop="title"
              label="商品名称"
              min-width="200"
              header-align="center"
              align="center" />
            <el-table-column
              prop="whl_code"
              label="库位"
              min-width="80"
              header-align="center"
              align="center">
            </el-table-column>
            <el-table-column
              prop="qty"
              label="库存"
              min-width="60"
              header-align="center"
              align="right">
              <template slot-scope="{row}">
                <el-link type="primary" @click="handleQty(row)">
                  {{ row.qty }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="updated_at"
              label="更新时间"
              min-width="120"
              header-align="center"
              align="center" />
          </el-table>
        </div>
        <div>
          <el-row>
            <el-col :span="24">
              <div>
                <m-pagination :limit.sync='distribFrom.pageData.page_size'
                              :page.sync='distribFrom.pageData.current_page'
                              :total.sync='distribFrom.pageData.total'
                              :hidden='distribFrom.pageData.total===0'
                              @pagination='getProductWhlCodeQtyList'></m-pagination>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <el-dialog :title='changeFrom.info.title' :visible.sync='changeFrom.open' center width='50%' append-to-body
                 :close-on-click-modal='false'>
        <div style="width: 95%;margin: 20px auto">
          <el-table :data="changeFrom.list" border style="width: 100%">
            <el-table-column
              type="index"
              width="50"
              label='序号'
              header-align="center"
              align="center" />
            <el-table-column
              prop="created_at"
              label="变更时间"
              min-width="100"
              header-align="center"
              align="center" />
            <el-table-column
              prop="c_no"
              label="关联单号"
              min-width="60"
              header-align="center"
              align="center" />
            <el-table-column
              prop="whl_code"
              label="库位"
              min-width="60"
              header-align="center"
              align="center" />
            <el-table-column
              prop="_change_type"
              label="变更原因"
              min-width="60"
              header-align="center"
              align="center">
              <template slot-scope='{row}'>
                <span v-if="row.change_type===2">
                  {{ row._change_type }} / {{ row._event_type }} /{{ row._obj_name }}
                </span>
                <span v-else>
                {{ row._change_type }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="_change_qty"
              label="变更数量"
              min-width="60"
              header-align="center"
              align="right" />
            <el-table-column
              prop="whl_aft_qty"
              label="库位库存"
              min-width="60"
              header-align="center"
              align="right" />
            <el-table-column
              prop="aft_qty"
              label="总库存"
              min-width="60"
              header-align="center"
              align="right" />
          </el-table>
        </div>
        <div>
          <el-row>
            <el-col :span="24">
              <div>
                <m-pagination :limit.sync='changeFrom.pageData.page_size'
                              :page.sync='changeFrom.pageData.current_page'
                              :total.sync='changeFrom.pageData.total'
                              :hidden='changeFrom.pageData.total===0'
                              @pagination='getProductQtyChangeList'></m-pagination>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportProductQty from './ImportProductQty'

export default {
  name: 'ProductQtyList',
  components: { ImportProductQty, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'product_qty',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        title: null,
        barcode: null,
        brand: null,
        leader_name: null,
        qty: null,
        qty_op: null
      },
      pageData: { current_page: 1, page_size: 15 },
      orderBy: { id: 'asc' },
      qtyOpOptions: [
        { label: '>', value: '>' },
        { label: '<', value: '<' },
        { label: '=', value: '=' }
      ],
      defaultColumns: [{ hide: false, prop: 'title', label: '产品名称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'pic', label: '产品图', width: 60, sortable: false, fixed: false, type: 'image' },
        { hide: false, prop: 'spec_name', label: '规格', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'brand', label: '品牌', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: '_qty_distrib', label: '库位分布', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'qty', label: '库存', width: 120, sortable: true, fixed: false },
        { hide: false, prop: '_far_qty_at', label: '库龄(天)', width: 120, sortable: true, fixed: false },
        { hide: false, prop: '_far_produce_at', label: '过期时间', width: 120, sortable: true, fixed: false }
      ],
      // 弹出层标题
      title: '添加/修改-库存',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 库存变更记录
      changeFrom: {
        info: { title: '库存变更记录' },
        open: false,
        searchCondition: { wh_id: null, product_id: null },
        pageData: { current_page: 1, page_size: 10, total: 0 },
        orderBy: { created_at: 'desc' },
        list: []
      },
      // 库位分布
      distribFrom: {
        info: { title: '库位分布' },
        open: false,
        searchCondition: { wh_id: null, product_id: null },
        pageData: { current_page: 1, page_size: 10, total: 0 },
        orderBy: { whl_code: 'desc' },
        list: []
      },
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:product_qty'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    addCellClass({ column }) {
      if (column && column.property === '_qty_distrib') {
        return 'cell-link'
      }
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getProductQtyList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteProductQty(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-库存'
      // let { info } = await this.$api.getProductQtyInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-库存'
      // let { info } = await this.$api.getProductQtyInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveProductQty(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = this.changeFrom.open = this.distribFrom.open = false
      this.changeFrom.searchCondition = this.distribFrom.searchCondition = { wh_id: null, product_id: null }
      this.form = this.changeFrom.list = this.distribFrom.list = []
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportProductQtys(searchCondition)
        let name = `库存`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    // 显示库存变更记录
    viewChangeList(row) {
      if (row.wh_id && row.product_id) {
        this.changeFrom.open = true
        this.changeFrom.searchCondition.wh_id = row.wh_id
        this.changeFrom.searchCondition.product_id = row.product_id
        this.loadingCommit = true
        this.getProductQtyChangeList()
      }
    },
    showInput(row, column) {
      if (column.property === '_qty_distrib') {
        this.distribFrom.open = true
        if (row.product_id) {
          this.distribFrom.searchCondition.wh_id = row.wh_id
          this.distribFrom.searchCondition.product_id = row.product_id
          this.loadingCommit = true
          this.getProductWhlCodeQtyList()
        }
      }
    },
    // 库位分布接口
    async getProductWhlCodeQtyList() {
      this.distribFrom.list = []
      this.loading = true
      let params = this.handleDialogSearchCondition(this.distribFrom)
      let { list, pages } = await this.$api.getProductWhlCodeQtyList(params)
      this.distribFrom.list = list
      this.distribFrom.pageData.current_page = pages.current_page || 1
      this.distribFrom.pageData.page_size = pages.page_size || 10
      this.distribFrom.pageData.total = pages.total
      this.loading = false
    },
    // 库存变更记录
    async getProductQtyChangeList() {
      this.changeFrom.list = []
      this.loading = true
      let params = this.handleDialogSearchCondition(this.changeFrom)
      let { list, pages } = await this.$api.getProductQtyRecordListByProduct(params)
      this.changeFrom.list = list
      this.changeFrom.pageData.current_page = pages.current_page || 1
      this.changeFrom.pageData.page_size = pages.page_size || 10
      this.changeFrom.pageData.total = pages.total
      this.loading = false
    },
    // 库位分布查询获取 分页 +排序
    handleDialogSearchCondition(from) {
      let condition = from.searchCondition
      // 分页 +排序
      Object.assign(condition, from.pageData, { orderBy: from.orderBy })
      return condition
    },
    handleQty(row) {
      this.$prompt('请输入更新的库存数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]+$/,
        inputErrorMessage: '请输入数字'
      }).then(({ value }) => {
        if (!value || value === '') {
          this.msgError('请输入库存数')
          return
        }
        this.$message.success('修改成功')
        // 发送接口请求
        this.$api.checkProductWhlCodeQty(row.id, value).then(response => {
          if (response.id) {
            this.getProductWhlCodeQtyList();
          }
        })
      }).catch(() => {
      })
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.searchCondition.qty_op = '>'
    this.handleQuery()
  }
}
</script>

<style>
.cell-link {
  text-decoration-color: #ff3176;
  text-underline-position: under;
  text-decoration-line: underline;
}
</style>
<style scoped>

</style>
